import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Just Launched Leetcode Solutions",
  "author": "tzookb",
  "type": "post",
  "date": "2020-12-13T13:50:08.000Z",
  "url": "/just-launched-leetcode-solutions",
  "featuredImage": "./thumb.jpg",
  "categories": ["news"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "leetcode-solutions.com"
      }}>{`Leetcode-Solutions.com`}</a>{` just launched!`}</p>
    <p>{`As you all know I love doing programming exercises, especially with LeetCode. So as I enjoy posting my solutions on this blog, I wanted to have a special place only for LeetCode.`}</p>
    <p>{`I would probably add videos to the posts there and include other people solutions and videos for exercises I didn't solve yet.`}</p>
    <p>{`With the above intro, please go visit the site :)
and I would be happy to get some feedback or even better ping back on any exercises you want me to solve.`}</p>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/ZcKASxMYMKA9SQnhIl/giphy.gif",
        "alt": "hurray we launched",
        "title": "hurray"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      